<template>
  <div class="md-toolbar-row logo-wrapper">
    <router-link tag="a" :to="{path: '/'}">
      <div class="poster-header">
        <div class="poster-heading">
<!--          <picture>-->
<!--          </picture>-->
          <img src="../assets/logo.png"
               alt="Fac3d: Transform your pictures of faces to 3d models."
               class="face-logo">
          <h1 class="reveal-text">
            Fac3d
          </h1>
        </div>
        <!-- <div class="poster-sub-heading">
          <h2 class="reveal-text"> 2021 </h2>
        </div> -->
      </div>
    </router-link>
  </div>
</template>
<script type="text/babel">
export default {
  name: 'Fac3dHeader',
};
</script>
<style scoped lang="scss">
.face-logo {
  width: 30%;
}
.logo-wrapper {
  a {
    margin: 0 auto;
  }
}
</style>
